export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Il nostro menù',
    route: 'menu-page',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Ordina online',
    route: 'ordina-page',
    icon: 'ShoppingBagIcon',
  },
  // {
  //   title: 'Dove siamo',
  //   route: 'dove-page',
  //   icon: 'MapPinIcon',
  // },
  // {
  //   title: 'Su di noi',
  //   route: 'about-page',
  //   icon: 'AwardIcon',
  // },
  {
    title: 'Profilo',
    route: 'profilo-page',
    icon: 'UserIcon',
  },
]
